<template>
  <div class="tree">
    <!-- current trees -->
    <div :class="{'root-node': root, 'intermediate-node': !root && children.length, 'leaf-node': !root && children.length === 0, 'disable': node.locked}"
         @click="collapse = !collapse">
      <div v-if="children.length"
           class="node-collapse"
           :class="{'collapse': collapse}">
        <custom-icon :icon="$vs.rtl ? 'CHEVRON_LEFT' : 'CHEVRON_RIGHT'" width="35px" height="20px"/>
      </div>

      <div class="node-content" @click="children.length === 0 ? $emit('node:click', node) : ''">
        <custom-check-box v-if="config.hasOwnProperty('selectable') && config.selectable && (config.parentSelectable || children.length === 0)"
                          :multi="config.hasOwnProperty('multiSelect') ? config.multiSelect : true"
                          :value="node"
                          v-model="selectedNodes"
                          @checkBoxCLicked="handleCheckBoxClick"
                          @input="handleCheckboxInputChange">
          <span class="node-label">
            {{ node.label }}
          </span>
        </custom-check-box>

        <span class="node-label" v-else>
          {{ node.label }}
        </span>

        <div v-if="node.hasOwnProperty('badge')" class="badge-content">
          <custom-icon v-if="node.badge.hasOwnProperty('type') && node.badge.type === 'icon'" :icon="node.badge.icon" :color="node.badge.color" height="15px" width="15px" />
          <span v-else class="badge-text" :class="[`bg-${node.badge.color}`]">
          {{ node.badge.value }}
        </span>
        </div>
      </div>

      <div v-if="node.extra" class="node-extra-content" @click="children.length === 0 ? $emit('node:click', node) : ''">
        <span :class="[node.extra.value < 0 ? '' : 'text-danger']"
              v-if="typeof node.extra === 'object' && node.extra.hasOwnProperty('type') && node.extra.type === 'price'">
          <template v-if="node.extra.value">
            <span class="price-value" :class="[{'rtl': $vs.rtl}]">
              {{ `${node.extra.value < 0 ? '' : '-'}${addComma(node.extra.value || 0)} ` }}
            </span>
          {{ `${ $u.locale.currency()}` }}
          </template>
        </span>
        <span :class="[typeof node.extra === 'object' && node.extra.classes]"
              v-else>
          {{ node.extra.value || node.extra }}
        </span>
      </div>

      <div class="node-actions" v-if="node.hasOwnProperty('firstHideActions') ? node.firstHideActions : true">
        <div class="node-action"
             :class="{'disable': actions.hasOwnProperty(action.key) && actions[action.key] === false}"
             v-for="(action, action_index) in config.actions"
             :key="`tree-${node.id}-action-${action_index}`"
             @click.stop="!actions.hasOwnProperty(action.key) || actions[action.key] === true ? $emit(action.event, node) : ''">

          <custom-icon :icon="action.icon"
                       :color="action.color"/>
        </div>
      </div>
    </div>

    <!-- children trees -->
    <div class="children"
         v-if="children.length"
         v-show="collapse">
      <custom-tree v-for="(child, child_index) in children"
                   :key="child.hasOwnProperty('children') && child.children.length ? `intermediate-node-${child_index}` : `leaf-node${child_index}`"
                   :node="child.node"
                   :config="config"
                   :children="child.hasOwnProperty('children') && child.children.length ? child.children : []"
                   :actions="child.hasOwnProperty('actions') && child.actions ? child.actions : {}"
                   v-dynamic-events="allEvents"
                   v-model="selectedNodes"/>
    </div>
  </div>
</template>

<script>
import CustomIcon from '../customIcon/customIcon'
import CustomCheckBox from '../customCheckBox/customCheckBox'

export default {
  name: 'CustomTree',
  components: {CustomCheckBox, CustomIcon},
  model: {
    prop: 'selectedNodes',
    event: 'input'
  },
  props: {
    root: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    node: {
      type: Object,
      default: () => {
        return {}
      }
    },
    config: {
      type: Object,
      default: () => {
        return {
          actions: [
            {
              key: 'insert',
              icon: 'PLUS',
              color: 'success',
              event: 'insert'
            },
            {
              key: 'update',
              icon: 'EDIT',
              color: 'warning',
              event: 'update'
            },
            {
              key: 'delete',
              icon: 'TRASH',
              color: 'danger',
              event: 'delete'
            }
          ],
          collapse: true
        }
      }
    },
    children: {
      type: Array,
      default: () => {
        return []
      }
    },
    actions: {
      type: Object,
      return: () => {
        return {}
      }
    },
    selectedNodes: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      collapse: true
    }
  },
  created () {
    if (this.config.hasOwnProperty('collapse')) this.collapse = this.config.collapse
  },
  computed: {
    allEvents () {
      let events = this.config.actions.map((action) => {
        return action.event
      })
      events.push('node:click')
      events.push('node:select')
      events.push('node:unselect')
      return events
    }
  },
  directives: {
    DynamicEvents: {
      bind (el, binding, vnode) {
        const allEvents = binding.value
        allEvents.forEach((event) => {
          // register handler in the dynamic component
          vnode.componentInstance.$on(event, (eventData) => {
            vnode.context.$emit(event, eventData)
          })
        })
      },
      unbind (el, binding, vnode) {
        vnode.componentInstance.$off()
      }
    }
  },
  methods: {
    addComma(value) {
      return this.$u.priceFormat(value.toString().replaceAll('-', ''), true)
    },
    handleCheckBoxClick (event) {
      event.stopPropagation()
    },
    handleCheckboxInputChange () {
      if (this.config.hasOwnProperty('multiSelect') && !this.config.multiSelect) {
        this.selectedNodes.forEach(item => {
          if (item.id !== this.node.id) {
            item.show = false
          }
        })
      }
      this.selectedNodes[this.selectedNodes.map(e => e.id).indexOf(this.node.id)].show ? this.$emit('node:select', this.node) : this.$emit('node:unselect', this.node)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/vuexy/variables";

.tree {
  display: flex;
  flex-direction: column;

  .root-node, .intermediate-node, .leaf-node {
    display: flex;
    flex-grow: 1;
    border: 1px solid var(--u-border-color);
    border-radius: var(--global-radius);
    align-items: center;
    min-height: 35px;
    cursor: pointer;

    .node-collapse {
      cursor: pointer;
      transform: rotate(0);
      transition: all ease .3s;

      &.collapse {
        transform: rotate(90deg);
      }
    }

    .node-content {
      display: flex;
      //justify-content: space-between;
      flex-grow: 1;

      .node-label {
        font-weight: 600;
      }

      .badge-content {
        margin-left: 5px;

        .badge-text {
          padding: 0 5px;
          border-radius: 50%;
          font-size: 11px;
          color: #fff;
        }
      }
    }

    .node-extra-content {
      padding-right: 10px;
      font-weight: 700;
      color: #000;

      span {
        display: flex;
      }

      .price-value {
        margin: 0 5px;
      }
    }

    .node-actions {
      display: flex;

      .node-action {
        padding: 5px;
        cursor: pointer;
        border-left: 1px solid var(--u-border-color);

        &.disable {
          cursor: not-allowed;

          .icon-component {
            opacity: 0.25;
          }
        }
      }
    }

    &.disable {
      cursor: not-allowed;

      .node-content {
        opacity: .25;
        pointer-events: none;
      }
    }
  }

  .children {
    margin-left: 35px;
  }

  .intermediate-node, .leaf-node {
    margin-top: .5rem;
  }

  .leaf-node {
    padding-left: 10px;
  }
}
</style>
